<template>
    <div class="interfaceConfig-addPop">
        <div class="handle-btn">
            <el-button type="primary" size="small" @click="handleSave">
                保存
            </el-button>
        </div>
        <el-form ref="form" :model="form" label-width="150px">
            <el-form-item label="接口名称">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="接口说明">
                <el-input v-model="form.remarks"></el-input>
            </el-form-item>
            <el-form-item label="服务地址">
                <el-input v-model="serviceUrl" disabled></el-input>
            </el-form-item>
            <el-form-item label="接口模式">
                <el-select v-model="form.interface_mode" placeholder="请选择接口模式">
                    <el-option label="覆盖" :value="0"></el-option>
                    <el-option label="追加" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="有效期">
                <el-date-picker
                    v-model="validDate"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否启用">
                <el-switch
                    v-model="is_enabled"
                >
                </el-switch>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: Object,
        },
    },
    data() {
        return {
            serviceUrl: 'http://39.106.58.200:31000/swagger/index.html', // 接口服务地址
            validDate: [], // （合并的）有效期
            is_enabled: true, // 是否启用
            form: {},
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 保存
        handleSave() {
            if (this.validDate && this.validDate.length === 2) {
                this.form.valid_begin_date = this.validDate[0];
                this.form.valid_end_date = this.validDate[1];
            }
            if (this.is_enabled) {
                this.form.is_enabled = 1;
            } else {
                this.form.is_enabled = 0;
            }
            this.form.directory_id = this.extr.directoryId;
            this.$axios
                .post('/interfaceApi/datacenter/interface/info/save', this.form)
                .then(res => {
                    if (res) {
                        this.$message.success('保存成功');
                        this.extr.closeCall();

                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
    },
    created() {
        // 修改
        if (this.extr.rowData) {
            this.form = this.extr.rowData;
            this.validDate.push(this.extr.rowData.valid_begin_date);
            this.validDate.push(this.extr.rowData.valid_end_date);
            this.is_enabled = this.extr.rowData.is_enabled === 1 ? true : false;
        }

    },
    mounted() {},
};
</script>
<style lang="stylus">
.interfaceConfig-addPop
    width 100%
    padding .2rem
    .handle-btn
        text-align right
        margin .1rem 0
</style>